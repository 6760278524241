import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Tab, Collapse, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';
import Switch from "react-switch";
import Cookies from 'js-cookie';

const UserSettings = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [errors, setErrors] = useState({});
  const [collapsible, setCollapsible] = useState('');

  const handleToggleButton = async (button) => {
    try {

      if (button === 'mobileNumberVisible') {
        const newValue = gContext.mobileNumberVisible === 0 ? 1 : 0;
        gContext.setMobileNumberVisible(newValue);
        await handleSettingsUpdate(newValue, null);

      } else if (button === 'profileImageVisible') {
        const newValue = gContext.profileImageVisible === 0 ? 1 : 0;
        gContext.setProfileImageVisible(newValue);
        await handleSettingsUpdate(null, newValue);

      }
    } catch (error) {
      console.error('Error toggling button:', error);
    }
  };

  const handleSettingsUpdate = async (mob, prof) => {

    console.log('Handle Settings Update clicked', mob, prof);

    setErrors({});

    // if (!userId) {
    //   gContext.toggleSignInModal();
    //   return
    // }

    try {

      const formData = new FormData();

      if (mob !== null) {
        formData.append('mobile_number_visible', mob);
      }
      if (prof !== null) {
        formData.append('profile_image_visible', prof);
      }
      formData.append('user_id', userId);

      const response = await axios.post(
        `${network.serverip}/api/update-privacy-settings-web`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success) {
        console.log(`User privacy settings updated successfully:`);

        if (mob !== null) {
          Cookies.set('mobileNumberVisible', JSON.stringify(mob), { expires: 30 });
          let user = JSON.parse(Cookies.get('user'));
          user.mobile_number_visible = mob;
          Cookies.set('user', JSON.stringify(user), { expires: 30 });
          gContext.setUser(prev => ({
            ...prev,
            mobile_number_visible: mob
          }));
        }

        if (prof !== null) {
          Cookies.set('profileImageVisible', JSON.stringify(prof), { expires: 30 });
          let profile = JSON.parse(Cookies.get('profile'));
          profile.image_visible = prof;
          Cookies.set('profile', JSON.stringify(profile), { expires: 30 });
          gContext.setProfile(prev => ({
            ...prev,
            image_visible: prof
          }));
        }

      } else {

        if (mob !== null) {
          gContext.setMobileNumberVisible(mob === 0 ? 1 : 0);
        }

        if (prof !== null) {
          gContext.setProfileImageVisible(prof === 0 ? 1 : 0);
        }
      }

    } catch (error) {
      console.error('Error uploading user privacy settings:', error);

      if (mob !== null) {
        gContext.setMobileNumberVisible(mob === 0 ? 1 : 0);
      }

      if (prof !== null) {
        gContext.setProfileImageVisible(prof === 0 ? 1 : 0);
      }
    }
  }

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);


  const styles = UserSettingsStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">
                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'privacySettings' ? '' : 'privacySettings')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        <i className="fas fa-lock font-size-6 text-success mr-5"></i>
                        Privacy Settings
                      </h4>
                      <i
                        className={`fas ${collapsible === 'privacySettings' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'privacySettings'}>
                      <div id="privacy-settings-collapse">
                        <div className="w-100 border-top">
                          <div className="d-flex justify-content-between mt-10 mb-10">
                            <div className="d-flex align-items-start mt-0">
                              <i className="fas fa-user-circle font-size-4 text-success mr-5"></i>
                              <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                Profile Image Visibility
                              </p>
                            </div>
                            <div className="d-flex align-items-start mt-0">
                              <Switch
                                onChange={() => handleToggleButton('profileImageVisible')}
                                checked={gContext.profileImageVisible === 1 ? true : false}
                                offColor="#888"
                                onColor="#198754"
                                offHandleColor="#fff"
                                onHandleColor="#fff"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                height={22}
                                width={42}
                                handleDiameter={20}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-10 mb-10">
                            <div className="d-flex align-items-start mt-0">
                              <i className="fas fa-mobile-alt font-size-4 text-success mr-5"></i>
                              <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                Phone Number Visibility
                              </p>
                            </div>
                            <div className="d-flex align-items-start mt-0 font-size-4">
                              <Switch
                                onChange={() => handleToggleButton('mobileNumberVisible')}
                                checked={gContext.mobileNumberVisible === 1 ? true : false}
                                offColor="#888"
                                onColor="#198754"
                                offHandleColor="#fff"
                                onHandleColor="#fff"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                height={22}
                                width={42}
                                handleDiameter={20}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default UserSettings;

function UserSettingsStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

